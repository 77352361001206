<template>
  <div class="loginInviter column align-center">
    <img :src="bg" alt="" class="bg">
    <div class="row input align-center mt40 font26">
      <span class="label row flex-center fontwb">手 机</span>
      <input type="number" class="flex1" placeholder="请输入您的手机号" v-model="mobile" />
    </div>
    <div class="row input align-center top1 font26">
      <span class="label row flex-center fontwb">验证码</span>
      <input type="text" class="flex1" v-model="sms_code" placeholder="请输入验证码" />
      <div v-show="sendAuthCode" class="ml16 font26 f-2295F6 mr10" @click="getAuthCode">获取验证码</div>
      <div v-show="!sendAuthCode" class="ml16 font26 f-999999 mr10"><span class="auth_text_blue">{{auth_time}} </span> 秒后重新发</div>
    </div>
    <p class="font20 mt30" style="color:#666666;">注册即代表您已同意并遵守<span style="color:#51A6FE;" @click="onAgress">《用户协议》</span></p>
    <img :src="btn" alt="" class="btn mt20" @click="login">
    <p class="font24 mt20 row align-center mb25" style="color:#666666;height:.533333rem;">我已注册 <span @click="download" class="h100 row align-center ml5" style="color:#51A6FE;">下载APP</span></p>
  </div>
</template>

<script>
// @ is an alias to /src

import {reg,sms_codes} from 'network/login'

export default {
  name: 'loginInviter',
  data() {
    return {
      bg:'http://rhb-mini-apps-images.igolife.net/common/inviter_banner@2x.png',
      btn:'http://rhb-mini-apps-images.igolife.net/common/inviter_zhuce-but@2x.png',
      sms_code:'',
      mobile: '',
      sendAuthCode:true,/*布尔值，通过v-show控制显示‘获取按钮’还是‘倒计时’ */
      auth_time: 0, /*倒计时 计数器*/
      invitation_code:this.$route.query.id || ''
    }
  },
  methods: {
    download(){
      location.href = 'https://app.igolife.net/app_download';
    },
    onAgress(){
      location.href = 'https://app.igolife.net/signup_agreement';
    },
    login() {
      if(!this.mobile) {
        this.$toast.show('手机号不能为空');
        return;
      }
      if(!this.sms_code) {
        this.$toast.show('验证码不能为空');
        return;
      }
      let params = {
        mobile: this.mobile,
        sms_code: this.sms_code,
        invitation_code:this.invitation_code
      }
      reg(params).then(res=>{
        this.$toast.show('注册成功');
        //去下载页
        //this.download();
      });
    },
    /**
     * 获取短信验证码
     */
    getAuthCode() {
      if(!this.mobile) {
        this.$toast.show('请输入手机号');
        return;
      }
      sms_codes(this.mobile).then(res=>{
        this.$toast.show('发送成功');
        this.sendAuthCode = false;
        this.auth_time = 60;
        var auth_timetimer =  setInterval(()=>{
            this.auth_time--;
            if(this.auth_time<=0){
                this.sendAuthCode = true;
                clearInterval(auth_timetimer);
            }
        }, 1000);
      },err=>{
        console.log(err)
      })
    },
    
  }
}
</script>
<style  scoped>
.loginInviter{
  background: white;
}
.bg{
  width:10rem;
  height:10.56rem;
}
.input{
  width:8.426667rem;
  height:1.173333rem;
  background:rgba(255,255,255,1);
  border:1px solid rgba(153,153,153,1);
  border-radius:.16rem;
  color:#1A1A1A;
}
.label{
  width: 2.146667rem;
  text-align: center;
}
.top{
  margin-top: 1rem;
}
.top1{
  margin-top: .4rem;
}
.btn{
  width: 8.426667rem;
  height: 1.2rem;
}
</style>
